// Variables
$zStart : 150;
.dots{
	@for $i from $dotMin through $dotMax{
		.size#{$i} {
			font-size: $i * 1px;
			margin-left: - ($i/2) * 1px;
			margin-top: - ($i/2) * 1px;
			z-index: $zStart + $i;
		}
	}
}
