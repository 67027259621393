/* account for editors adding embeds */
iframe,
object,
embed {
	max-width:100% !important;
}
html,
body{
	width:100%;
	margin:0;
	padding:0;
	overflow-x:hidden;
}
 a,
a:hover,
a:active,
a:focus,
a:visited{
	text-decoration: none !important;
}

/* BEGIN Bootstrap Overrides */
.container {
	margin-left: 0;
	margin-right: 0;
	width: auto !important;
}
img{
	@include img-responsive();
	display: inline-block;
}
body > .container{
	@media (min-width: $screen-sm){
		padding-left: $sidebar-width + $ggw !important;
		padding-right: $ggw;
		.imageSlider{
			margin-left: $ggwh * -1;
			margin-right: $ggwh * -1;
		}
		.gmaps-row {
			margin-left: $ggw * -1.5;
			margin-right: $ggw * -1.5;

		}

	}
}
.container-full{
	// padding-left: 0;
	// padding-right: 0;
	@media (min-width: $screen-sm){
		padding-left: $ggwh;
		padding-right: $ggwh;
	}
}
a, a:hover{text-decoration:none;}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

form {
	margin: 0 0 12px;
}
ul, ol {
	padding:0 0 9px 12px;
	margin: 0;
	list-style:disc;
	&.list-unstyled{
		padding: 0;
		ul, ol {
			padding:0 0 9px 12px;
		}
	}
}
li {
	line-height:17px;
}
.column-content,
.accordion{
	ul,
	ol{
		list-style:none;
		li{
			position: relative;
			&:before{
				content:"\00b7";
				position: absolute;
				left: -12px;
				top:0em;
			}
			margin-bottom: 0.5em;
			ul,ol{
				margin-top: 0.5em;
			}
		}
	}
}

/* END Bootstrap Overrides */
/*  Main Layout */



/* Additional Utility Classes */
.row-buffer
{
	margin-top:$ggwh;
	margin-bottom:$ggwh;
}
.row-no-gutter{
	@media (max-width: $screen-xs-max){
		margin-left: -$ggw;
		margin-right: -$ggw;
	}
}
.row-no-gutter-all{
	margin-left: -$ggw;
	margin-right: -$ggw;
}

.clearfix { clear:both; }

.clear-left {
	clear:left;
}
.clear-right {
	clear:right;
}
.no-margins {
	margin:0 !important;
}
.no-left-margin {
	margin-left:0 !important;
}
.no-right-margin {
	margin-right:0 !important;
}
.no-top-margin {
	margin-top:0 !important;
}
.no-bottom-margin {
	margin-bottom:0 !important;
}
.left-margin {
	margin-left:$ggwh !important;
}
.right-margin {
	margin-right:$ggwh !important;
}
.top-margin {
	margin-top:$ggwh !important;
}
.bottom-margin {
	margin-bottom:$ggwh !important;
}
/* BEGIN jQUery UI Overrides  */

.ui-tabs
{
	padding: 0;
}
.ui-tabs .ui-tabs-nav {
	padding: 0;
}
.ui-tabs-nav.ui-widget-header {
	border: 0;
}
.ui-widget-content a {
	color: $brand-primary;
}

/* END UI Overrides */
/* BEGIN Toronto Subway font declarations */

h1,
h2,
h3,
h4,
h5,
h6,
.ui-tabs .ui-tabs-nav li a,
#mainNav,
.newer-post-link,
.older-post-link,
.sounder-wwg a,
body .ui-accordion .ui-accordion-header,
#filters a {
	font-family: "TorontoSubway" !important;
	font-weight: normal;
	font-style: normal;
	letter-spacing:1px;
}
#place-list li a,
#filters .clear-filters,
#filters #surprise-me,
#filters .filter-warning,
#sounder-articles .post-title h2,
h2.single-title,
h2.pageTitle,
.sounder-nav-links,
.sounder-nav-links a,
#filters #show-filters,
#map-overlay{
	font-family:TorontoSubway;
	font-weight: bold;
	font-style: normal;
	letter-spacing:1px;
}
#filters {
	ul.children{
		display:none;
	}
}


.ui-tabs .ui-tabs-nav li a,
#mainNav,
#place-list {
	text-transform:uppercase;
}

#mainNav ul{
	padding-left: 9px;
}

#mainNav a {
	color:$white;
}
	#mainNav a:hover{
		color:$brand-primary;
		text-decoration:none;
	}

	.gmaps-row{
		position:relative
	}
	#map-overlay {
		cursor: pointer;
		height: 500px;
		position: absolute;
		margin:0 !important;
		z-index: 1000;
		top:0;
		left:10px;
		text-transform:uppercase;
	}


.container,
.navbar-fixed-top .container,
.navbar-fixed-bottom .container {
	// width: 1052px;
}
.col-xs-12.points-of-interest{
	margin-bottom: -$ggw;
	margin-top: $line-height-computed;
	h3{
		margin-top: 0;
	}
}
/* END Toronto Subway font declarations */
h1,
h2,
h3,
h4,
h5
{
	text-transform:uppercase;
}
.main-content-span{
	padding-bottom:$ggw;
}
@media (min-width: $screen-sm){

	.navbar-fixed-top {
		height:auto;
		position:fixed;
		top:0;
		left:0;
		bottom: 0;
		margin-bottom: 0;
		width: $sidebar-width;
		margin-left:0;
		z-index:500; /* needs to be higher than homepage Window divs */
	}
}
.admin-bar .navbar-fixed-top{
	top: 32px;
	@media screen and ( max-width: 782px ) {
		top: 46px;
	}
}
#wpadminbar{
	position: fixed !important;
}
.navbar-fixed-top {
	background:$gray;
	ul{
		@include list-unstyled;
	}
}
.navbar-toggle{
	width: $sidebar-width / 2;
	margin-right: 0;
	svg{
		height:100%;
		width: 100%;
		polygon,
		path{
			fill:$brand-primary;
		}
	}
}
.tp-brand{
	padding-left: $ggwh;
	padding-right: $ggwh;
	width: $sidebar-width;
	@media (min-width: $screen-sm){
		padding-right: $ggw;
		padding-left: $ggw;
		padding-top: 1.5 * $ggw;
		padding-bottom: 1.5 * $ggw;
		height: 100%;
	}
	@media (max-width: $screen-xs-max){
		max-width: $sidebar-width;
		height: 100%;
	}
	svg{
		height: 59px;
		width: 100%;
		path{
			fill:$white;
		}
	}
}

button{
		margin:0;
		padding: 0;
		padding-right: 6px;
}

@media (max-width: $screen-xs-max){
	#navbar{
		position: absolute;
		top: 0;
		left: 0;
		height:100vh;
		width: 100vw;
		z-index: -1;
		overflow-y: auto;
	}
	#navbar,
	.navbar-header{
		background: $gray;

	}
}
#secondaryNav{
	padding-top: $ggw;
	background: $gray;
	input{
		color:$gray;
		padding:0 $ggwh;
	}
	h4{
		margin: 0;
		font-size: 14px;
		line-height: 18px;
	}
}
@media (max-width: $screen-xs-max){
	body,
	#navbar{
		padding-top: $navbar-height;
	}
}
@media (min-height: 600px){
	#secondaryNav{
		position: absolute;
		bottom: 0;
	}
}
.collapsed{
	.collapsed-hide{
		display: none !important;
	}
	.collapsed-show{
		display: block !important;
	}
}
.home.page{
	.container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
		width: 100%; /* override bootstrap for just this page */
	}
	/*
	 */
	.blog-description{
		margin:$ggw 9px;
		color:$white;
	}
	.blog-description h4{
		text-transform:none;
		font-size:$font-size-base;
		line-height: 18px;
	}
	.textwidget{
		h4{
			margin: 4px 0px;
		}
	}
	#window,
	#window .dots,
	#window .front,
	#window .rear
	{
		&,
		.img-panel{
			position:absolute;
			top:0;
			left:0;
			right: 0;
			bottom: 0;
		}
		@media (min-width: $screen-sm){
			left:$sidebar-width;
		}
		.img-panel,
		.dot{
			@include transition(
				top 1000ms / $fps linear,
				left 1000ms / $fps linear
			);
		}
	}
	#window .front,
	#window .rear
	{
		.img-panel{
			top: -5%;
			left: -5%;
			width: 110%;
			height: 110%;
			background-position: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			border: 0;
			margin: 0;
			padding: 0;
		}
	}
	#window
	{
		left:0;
		overflow:hidden;
		.dots {
			z-index:150;
		}
		.front {
			z-index:100;
		}
		.rear {
			z-index:50;
		}
		.log{
			position: fixed;
			bottom: $ggw;
			left: $ggw;
			background: $gray;
			z-index: 1000;
			padding: $ggw;
			color:white;

		}
	.dot{
		-webkit-border-radius: 0.5em;
		border-radius: 0.5em;
		width:1em;
		height:1em;
		position:absolute;
		cursor:pointer;
	}
	.dot-white {
		background-color:#fff;
	}
	.dot-black {
		background-color:#000;
	}
	.dot:hover, .dot.hover{
		background-color:#EC008C;
	}
	.description {
		color: #000;
		position: absolute;
		left:10%;
		bottom:10%;
		width:50%;
		text-transform:none;
		color:#fff;
		font-size:16px;
		text-shadow: 2px 2px 2px #666;
		filter: dropshadow(color=#666, offx=2, offy=2);
	}
}
	}




#logo {
	background:url(../images/tp_logo.png) top left no-repeat;
	display:block;
	height:27px;
	margin:40px 0 40px 17px;
	overflow:hidden;
	text-indent:-10000px;
	width:216px;
}

#mainNav{
	li{
		font-size:18px;
		line-height:1.5;
	}
	.sub-menu {
		display:none; /* Normally Don't Show the sub menu */
		li{
			font-size:14px;
		}
	}
	.current-menu-item .sub-menu,
	.current-menu-ancestor .sub-menu
	{
		display:block; /* Show the sub menu when the parent or any children are current. */
	}
	.single-post & .magazine-menu-item a,
	.page-template-placePage & .current-page-ancestor a,
	li.current-menu-item a,
	li.current-menu-item .sub-menu li a:hover {
		color:$brand-primary;
	}
	li.current-menu-item .sub-menu li a {
		color:$white;
	}
}


#secondaryNav{
	padding-left: 17px;
	&,
	a {
		color:$white;
	}
	a:hover {
		text-decoration:none;
		color:$brand-primary;
	}
	h4,
	h5 {
		text-transform: none;
	}
	#searchform label {
		display:none;
	}
	#searchform #searchsubmit {
		background: none;
		border: 0 none;
		height: 25px;
		left: 155px;
		overflow: hidden;
		position: absolute;
		text-indent: -9999px;
		top: 0;
		width: 25px;
	}
	#searchform #s {
		padding: 5px;
		padding-right:$ggw;
		background: url(../images/tp_searchIcon.jpg) 145px 50% no-repeat #fff;
		height:30px;
		width: 165px;
	}
}
@media (min-width: $screen-sm){
	#mainNav,
	#secondaryNav > nav{
		padding-left: 0.4em;
	}
}

#searchform {
	position:relative;
}

/* Where We Go */


#place-list{
	padding: 0;
	li{
		list-style:none;
		margin-bottom:$ggw;
		@media (min-width: $screen-lg){
			// max-width: $sidebar-width;
		}
	}
	li img,
	li h3,
	li a {
		width:100%;
		height:100%;
		margin: 0;
	}
	li img
	{
		width:100%;
		height:100%;
		position:absolute;
		top:0;
		left:0;
		margin-top: 0;
		z-index: -1;
	}
		li a{


			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: center;
			justify-content: center;
			color:$white;
			font-size:1.8em;
			@media (max-width: $screen-xs-max){
				font-size:5vw;
			}
			span{
				text-align: center;
			}
		}
		li a:hover{
			text-decoration:none;
			color:$brand-primary;
			background:rgba(0,0,0,.6);
		}
}

/* Filters Sections */

#filters{
	margin:$ggwh / 2 0 $ggwh;
	h3{
		margin-top: 0;
	}
}
	#filters a, #sounder-articles .filter-warning a {color:#333; text-transform:capitalize;}
	#sounder-articles .filter-warning a,
	#filters .filter-warning a
	{color:$brand-primary;}
	#filters a:hover,
	#sounder-articles .filter-warning a:hover,
	#filters a.active{color:$brand-primary; text-decoration:none; }
	#filters .hidden{display:none;}
	#filters .clear-filters,
	#filters #surprise-me{text-transform:capitalize;}
	#filters #show-filters{text-transform:uppercase; color: $brand-primary; outline: 0;}
	.filter-warning{margin:$ggwh 0;}

#sounder-articles{
	.articles{
		article{
			margin-bottom: $ggw;
			@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
				&:nth-child(2n+1){
					clear: left;
					// background: rgba(green, $opacity-normal);
				}
			}
			.article-image{
				width: 100%;
			}

			@media screen and (min-width: $screen-md-min){
				&:nth-child(4n+1){
					clear: left;
					// background: rgba(red, $opacity-normal);
				}
			}
		}
		@media screen and (min-width: $screen-md-min){
			&.large-article{
				.post-title h2{
					margin-top: 0;
				}
			}
		}
	}

}


/* Default Page */
h2.pageTitle {
	font-size:45pt;
	@media (max-width: $screen-xs){
		font-size: 12vw;
	}
	line-height:0.8;
	margin:20px 0 0.25em;
}

h2.single-title { font-size:48px; font-family:'TorontoSubway'; font-weight: bold; line-height:1; margin:0.1em 0;}


/* Place Page */
h3.placePage, .placePageTitle { text-transform:uppercase; }
	h3.placePage { line-height:1; margin-bottom:9px; }
.placePageTitle {
	font-family: TorontoSubway;
	font-size: 80px;
	@media (max-width: $screen-xs-max){
		font-size: 10vw;
	}
	font-weight: bold;
	line-height: 0.8;
}


.page-template-placePage-php .post { margin-bottom:20px; margin-top:-0; }
	.page-template-placePage-php .post p, .post address { font-size:23px; line-height:1.2; }

ul.sounderThumbs { margin:0; list-style:none; }
	ul.sounderThumbs li { float:left; margin-bottom:9px; }
	ul.sounderThumbs li:first-child { margin-right: 9px; }
	ul.sounderThumbs li+li+li+li { margin-left: 9px; }

.priceGuideline {margin-bottom:35px; }
.onTheSounder { margin-bottom:5px; }
.tabbedContent { margin-bottom:22px; }
	.tabbedContent .ui-widget div { font-size:10pt; }
		.tabbedContent .ui-tabs .ui-tabs-panel { padding: 11px 9px 9px;}
		.tabbedContent .ui-tabs .ui-tabs-nav li a { padding:0.5em; }

#need-to-know li { line-height:1.5; margin-bottom:5px; }

#google-map.row { margin-bottom:20px; }

.gallery-caption,
.imageSlider .caption
{
	background:$white !important;
	padding: $ggwh $ggw $ggwh;
	text-align:right;
	position:absolute;
	bottom: 0;
	left: 0;
	color:$gray;
	text-shadow:none;
	width:100%;
	font-style:italic;
	font-size:12px;
}
.gallery-caption p,
.imageSlider .caption p
{
	margin:0;
	padding:0;
}

.shareThisPage{
	@media (min-width: $screen_sm){
		float: right;
	}
	h3{
		margin-top: 0;
	}
}

.page-template-placePage-php .gallery-caption p,
.imageSlider .caption p { margin-bottom:0; }
/* Sounder Articles */
.sounder-nav-links{
	font-size:1.2em;
	line-height:3;
}
.sounder-nav-links a{
	color:$gray;
	text-decoration:none;
}
.sounder-nav-links a:hover{
	color:$brand-primary;
	text-decoration:none;
}
.sounder-wwg {
	@include flex-shrink(0);
	@include flex-grow(1);
	padding-left: $ggwh;
	padding-right: $ggwh;
	&:first-child{
		@include flex-grow(0);
		// padding-left: $ggwh - 1;

	}
	margin-top: $ggw;
}
.row-sounder-wwg{
	@include flexbox;
	@include flex-flow(row wrap);
	margin-top: $ggw * -1;
	&.single{
		.sounder-wwg{
			@include flex-basis(100%);
			@include flex-grow(1);
		}
	}
	&.divides-by-2,
	&.default{
		.sounder-wwg{
			@include flex-basis(49%);
			@include flex-grow(1);
		}
	}
	&.divides-by-3{
		.sounder-wwg{
			@include flex-basis(49%);
			@media (min-width: $screen-lg){
				@include flex-basis(32%);
			}
			@include flex-grow(1);
		}
	}
}
.newer-post-link,
.older-post-link,
.sounder-wwg a {
	display:block;
	float:left;
	background:$black;
	position:relative;
	width: 100%;
	margin: 0;
	span {
		position:absolute;
		top:50%;
		left:0;
		color:$white;
		font-size:20px;
		line-height:20px;
		margin-top:-$ggwh;
		width:100%;
		text-align:center;
		text-transform: uppercase;
		font-size:16px;
	}
	&:hover span {
		color:$brand-primary;
	}
	&:hover img {
		opacity:$opacity-normal;
	}
	img{
		width: 100%;
		max-width: 100%;
	}
}
.sounder-meta{
	margin-top:$ggwh;
}
.sounder-sidebar div {
	margin-bottom:28px;
}
.sounder-sidebar blockquote{
	margin:0;
}
.sounder-thumbnail{
	margin-bottom: $ggw;
}
.keywords, .cat-list {
	color: $brand-primary;
	h2{
		color:$black;
	}
}

/* Icons */

.tp-icon {background:url(../images/tp_icons.png) no-repeat; display:inline-block; width:24px; height:24px; vertical-align:text-top; text-indent:-10000px; overflow:hidden; float:left;}
	.tp-icon-facebook {background-position:0 0;}
		.tp-icon-facebook:hover {background-position:0 -34px;}
	.tp-icon-twitter {background-position:-34px 0;}
		.tp-icon-twitter:hover {background-position:-34px -34px;}
	.tp-icon-pinterest {background-position:-68px 0;}
		.tp-icon-pinterest:hover {background-position:-68px -34px;}
	.tp-icon-email {background-position:-102px 0;}
		.tp-icon-email:hover {background-position:-102px -34px;}

	.tp-icon-facebook-w {background-position:0 -68px;}
		.tp-icon-facebook-w:hover {background-position:0 -34px;}
	.tp-icon-twitter-w {background-position:-34px -68px;}
		.tp-icon-twitter-w:hover {background-position:-34px -34px;}
	.tp-icon-pinterest-w {background-position:-68px -68px;}
		.tp-icon-pinterest-w:hover {background-position:-68px -34px;}
	.tp-icon-email-w {background-position:-102px -68px;}
		.tp-icon-email-w:hover {background-position:-102px -34px;}
	.tp-icon-snout-w {background-position:-136px -68px;}
		.tp-icon-snout-w:hover {background-position:-136px -34px;}

.tp-badge{
	font-size: 70px;
	color: $brand-primary;
	a:hover &{
		color:$gray;
	}
}
.tp-badge-link{
	display: inline-block;
	margin-right: $ggwh;
	margin-bottom: $ggwh;
}
.tp-badge-col{
	margin-top: $font-size-h3 + ($line-height-computed / 2);
}
@media (max-width: $screen-xs-max){
	.child-cats{
		margin-top: $ggwh;
	}
}

.socialMediaLinks {
	margin:15px 0;
	height:24px;
	li {
		float:left;
		margin-right: 8px;
		margin-left: -0.3em;
		padding: 0;
		a{
			font-size: 2.5em;
		}
	}
}

/* Flex slider Overrides */

body .flex-control-nav{display:none;}
body .flex-direction-nav .flex-prev{left:0;}
body .flex-direction-nav .flex-next{right:0;}
body .flex-direction-nav .flex-disabled {
	display:none;
}

.imageSlider {
	.flexslider { border:none; border-radius:none;}
}


/* Sounder Main Page */
.page-template-sounderMainPage-php .post{
	margin-bottom:25px;
}
.page-template-sounderMainPage-php .post h2 {
	line-height:1;
	margin-bottom:15px;
}
.sounder-header{
	text-align:left;
	color:$black;
	@media (max-width: $screen-xs-max){
		background: $gray-lighter;
	}
	&:hover{
		text-decoration: none;
		color:$brand-primary;
	}
	padding: $ggwh;
	h2 {
		font-size: 72px;
		@media (max-width: $screen-xs){
			font-size: 12vw;
		}
		line-height: 1;
		margin: 0;
		font-weight: bold;
		text-transform: uppercase;
	}
	.sounder-tagline{
		font-family: $font-family-serif;
		font-size:$font-size-h2;
		@media (max-width: $screen-xs){
			font-size: 5vw;
		}
		font-weight: 100;
		margin-top: 0.5em;
		font-style:italic;
	}
}
.post{margin-top:$ggwh; margin-bottom:$ggwh;}
.post-title a {
	color: $black;
}
.post-title a:hover, a.em-link, #filters a.em-link {
	color: $brand-primary;
	text-decoration: none;
}
#filters a.em-link:hover{
	text-decoration:underline;
}

/* Accordion */

.ui-accordion .ui-accordion-header  {
	outline:0;
}
.ui-accordion .ui-accordion-header .ui-icon {
	right: 0.5em;
	left:auto;
}
.shortcode-accordion .ui-widget-content {
	border: 0;
}
.ui-accordion .ui-state-active,
.ui-accordion .ui-state-default,
.ui-accordion .ui-widget-content .ui-state-hover{
	border:0;
	background: $gray-lighter;
	color: $black;
}
.ui-accordion.place-page-accordion,
.ui-accordion.shortcode-accordion {
	.ui-accordion-header{
		background: $gray;
		color: $white;
		&.ui-state-default .ui-icon{
			background-image: url("../jquery-ui-1.8.21.custom/css/custom-theme/images/ui-icons_ffffff_256x240.png")
		}
		&.ui-state-active{
			background: $gray-lighter;
			color: $brand-primary;
			.ui-icon{
				background-image: url("../jquery-ui-1.8.21.custom/css/custom-theme/images/ui-icons_ec008c_256x240.png")
			}
		}
	}
}
.ui-accordion .ui-accordion-header {
	margin: 0;
	margin-top: $line-height-computed;
	padding: $ggwh $ggw;
	text-transform:none;
	color:$gray;
}
.what-they-say,
.ui-accordion .ui-accordion-content {
	padding: $ggwh $ggw;
	border:0 !important;
}
.ui-accordion .ui-accordion-content {
	background: $gray-lighter !important;
}

.ui-accordion .ui-helper-reset { font-size:13px; }

.ui-accordion .ui-widget-content { color:#333; }


/* Lookup Shortcode */

.circle-pic {
	-webkit-border-radius: 50% ;
	border-radius: 50% ;
	overflow: hidden;
	float:left;
}
.lookup-header{
	clear:left;
	padding-top:0.5em;
}
.lookup{
	padding:$ggwh 0;
}

/* Blockquotes */
q:before, blockquote:before{
	content: "\201c";
	position:absolute;
	margin-left:-0.5em;

}
q:after, blockquote:after{
	content: "\201d";

}
q p, blockquote p {display:inline;}
q, blockquote { font-style:italic; border:none; margin:0; padding:0;}
q + p, blockquote + p{ padding-right:4em;}

#gmaps-poi a{display:block; line-height:1.2; margin:1em 0;}
#gmaps-poi a.hasCountry {margin:1em 0 0;}
#gmaps-poi a.gmaps-country-link {margin: 0.25em 0 1em; color:#333;}
#gmaps-poi a.gmaps-country-link:hover { color:$brand-primary;}


/* sprites */

/* The sprites */
[class^="sprite-"],
[class*=" sprite-"] {
  display: inline-block;
  vertical-align: text-top;
  background-image: url("../images/tpp/tpp-sprite.png");
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  text-emphasis: none;
}
/* The sprites */
[class^="sprite-"],
[class*=" sprite-"] {
  display: inline-block;
  vertical-align: text-top;
  background-image: url("../images/tpp/tpp-sprite.png");
  background-repeat: no-repeat;
  text-indent: -9999px;
  overflow: hidden;
  text-emphasis: none;
}
.sprite-the-call {
  width: 70px;
  height: 70px;
  background-position: 0px 0px;
}
.sprite-the-call:hover,
.sprite-the-call.hover {
  background-position: 0px -80px;
}
.sprite-pick-a-place-decide-on-dates {
  width: 70px;
  height: 70px;
  background-position: -80px 0px;
}
.sprite-pick-a-place-decide-on-dates:hover,
.sprite-pick-a-place-decide-on-dates.hover {
  background-position: -80px -80px;
}
.sprite-build-the-framework {
  width: 70px;
  height: 70px;
  background-position: -160px 0px;
}
.sprite-build-the-framework:hover,
.sprite-build-the-framework.hover {
  background-position: -160px -80px;
}
.sprite-colour-in-the-days {
  width: 70px;
  height: 70px;
  background-position: -240px 0px;
}
.sprite-colour-in-the-days:hover,
.sprite-colour-in-the-days.hover {
  background-position: -240px -80px;
}
.sprite-fine-tune {
  width: 70px;
  height: 70px;
  background-position: -320px 0px;
}
.sprite-fine-tune:hover,
.sprite-fine-tune.hover {
  background-position: -320px -80px;
}
.sprite-get-your-piglet {
  width: 70px;
  height: 70px;
  background-position: -400px 0px;
}
.sprite-get-your-piglet:hover,
.sprite-get-your-piglet.hover {
  background-position: -400px -80px;
}
.sprite-hit-the-road {
  width: 70px;
  height: 70px;
  background-position: -480px 0px;
}
.sprite-hit-the-road:hover,
.sprite-hit-the-road.hover {
  background-position: -480px -80px;
}
.sprite-bring-it-home {
  width: 70px;
  height: 70px;
  background-position: -560px 0px;
}
.sprite-bring-it-home:hover,
.sprite-bring-it-home.hover {
  background-position: -560px -80px;
}

$tpp-icon-size:200px;
#tpp-gallery{
	.tpp-cell {
		width: 100%;
		padding-left: $ggwh;
		padding-right: $ggwh;
		.tpp-cell-header{
			text-align: center;
			span{
				font-size: $tpp-icon-size;
			}
		}
	}
	.flickity-prev-next-button{
		top: $tpp-icon-size / 2;
	}


	@media (max-width: $screen-xs-max){
		padding-bottom: 10vw;
	}

}
#tpp-gallery-icons{
	margin-top: $ggw;
	&,
	.tpp-badge{
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	.tpp-badge{
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
	}
	.tpp-cell{
		border-radius:50%;
		cursor: pointer;
		width: 8%;
		background: $brand-primary;
		span{
			font-size: 400%;
			@media (max-width: $screen-xs-max){
				font-size: 6vw;
			}
			color:$white;
		}
		&.active ~ .tpp-cell{
			background: $gray-lighter;
			span{
				color:$brand-primary;
			}
			&:hover{
				background: $brand-primary;
				span{
					color:$white;
				}
			}
		}

	}
	.tpp-badge{
		width: 100%;
	}
	@media (max-width: $screen-xs-max){
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: transparent;
		@include gradient-vertical(rgba(255,255,255,0), rgba(255,255,255,1), 0%, 15%);
		padding: $ggw;
	}
}
/* override others */
html { -webkit-font-smoothing: subpixel-antialiased; }

.flex-direction-nav a{
	margin:-26px 0 0;
	top:45%;
}
.flexslider .slides img
{
	display:inline;
}
.flexslider .slides > li,
.single-slider-image
{
	text-align:center;
	background-color:$black;
	border:0;
	outline:none;
	padding:0;
}
.page-id-204 .flexslider .slides > li,
.single-slider-image .caption
{
	background-color:$white;
}
.flexslider .slides > li .gallery-caption
{
	text-align:right;
}
#show-filters [class^="icon-"], #show-filters [class*=" icon-"]{
	background-image:url(../images/WhereWeGoIcons.png);
	height:$ggwh;
	width:$ggwh;
	line-height:$ggwh;
	margin-top:6px;
}
#show-filters .icon-chevron-down {
	background-position: -10px 0;
}
#show-filters:hover .icon-chevron-down {
	background-position: -10px 0;
}
#show-filters .icon-chevron-up {
	background-position: -30px 0;
}
#show-filters:hover .icon-chevron-up {
	background-position: -30px 0;
}
.cat-item > a {
	outline : 0;
}
/* media queries */

@media all and (max-height:550px){
.blog-description{display:none;}
}
@media (max-height:600px) and (min-width: $screen-sm){
	#secondaryNav{
		display:none;
	}
}
@media all and (-webkit-min-device-pixel-ratio: 2){
	#logo{
		background:url(../images/tp_logo_x2.png) no-repeat scroll left top transparent;
		background-size:216px 27px;
	}
	.tp-icon {
		background: url(../images/tp_icons_x2.png) no-repeat scroll 0 0 transparent;
		background-size:160px 92px;
	}

	.tp-icon-facebook {
	  	background-position: 0 0;
	}

	.tp-icon-facebook:hover {
	    background-position: 0 -34px;
	}

	.tp-icon-twitter {
	    background-position: -34px 0;
	}

	.tp-icon-twitter:hover {
	    background-position: -34px -34px;
	}

	.tp-icon-pinterest {
	    background-position: -68px 0;
	}

	.tp-icon-pinterest:hover {
	    background-position: -68px -34px;
	}

	.tp-icon-email {
	    background-position: -102px 0;
	}

	.tp-icon-email:hover {
	    background-position: -102px -34px;
	}

	.tp-icon-facebook-w {
	    background-position: 0 -68px;
	}

	.tp-icon-facebook-w:hover {
	    background-position: 0 -34px;
	}

	.tp-icon-twitter-w {
	    background-position: -34px -68px;
	}

	.tp-icon-twitter-w:hover {
	    background-position: -34px -34px;
	}

	.tp-icon-pinterest-w {
	    background-position: -68px -68px;
	}

	.tp-icon-pinterest-w:hover {
	    background-position: -68px -34px;
	}

	.tp-icon-email-w {
	    background-position: -102px -68px;
	}

	.tp-icon-email-w:hover {
	    background-position: -102px -34px;
	}

	.tp-icon-snout-w {
	    background-position: -136px -68px;
	}

	.tp-icon-snout-w:hover {
	    background-position: -136px -34px;
	}

	.tp-badge {
	    font-size: 70px;
	    color: #EC008C;
	}
}

/* Begin CForms Override */

div.gform_wrapper {
	background:$gray-lighter;
	border:none;
	width:auto;
	padding-bottom:25px;
	.gfield {
		label {
			font-family:TorontoSubway;
			text-transform:uppercase;
			font-weight:normal !important;
			font-size:17px;
			display:block;
			text-align:left;
			width:auto;
			margin:0.6em 0 0.2em;
			color:$black;
		}
		margin-left:$ggwh !important;
	}
	.gsection{
		border-bottom: 0;
	}
	.gfield input, .gfield textarea {
		width:85%;
		margin-bottom:0;
		background:$white;
		border:none;
		font-size:1.1em;
		padding:4px 5px;
		font-family:Georgia,"Times New Roman",Times,serif;
	}
	.gfield input:hover, .gfield textarea:hover {
		box-shadow:none;
		border:none;
	}
	.gf_right_half{
		float: right !important;
	}
	.gf_left_half{
		float: left !important;
	}
	.newsletter .gfield_label{
		display: none !important;
	}
	.gfield_required{
		color: $brand-primary;
	}
/*******************
	Error State
********************/

	.validation_message,
	.validation_error{
		display: none !important;
	}
	li.gfield.gfield_error{
		background-color: inherit;
		border: 0 !important;
		input{
			border: 0 !important;
		}
		.gfield_label{
			margin-left: $ggwh !important;
			color: $brand-primary !important;
		}
	}

	.pig_1{
		&:after{
			background: url(../images/get-started-pig-1.png) no-repeat 50% 50%;
			height: 225px;
			right: 0;
		}
	}
	.pig_2{
		&:after{
			background: url(../images/get-started-pig-2.png) no-repeat 50% 50%;
			height: 225px;
			left: 0;
		}
	}
	.pig_3{
		&:after{
			background: url(../images/get-started-pig-3.png) no-repeat 50% 50%;
			height: 225px;
			right: 0;
			top: 50px !important;
		}
	}
	.newsletter_pig_1{
		&:after{
			background: url(../images/newsletter-pig-1.png) no-repeat 50% 50%;
			height: 225px;
			right: 0;
		}
	}
	.pig_1,
	.pig_2,
	.pig_3,
	.newsletter_pig_1{
		position: relative;
		&:after{
			content: " " !important;
			position: absolute;
			display: block;
			width: 50%;
			top: 0;
			@media (max-width: $screen-xs-max){
				background-size: contain;
				width: 40%;
			}
		}
	}
}
.entry .cform input.sendbutton ,
.gform_button.button,
body .cform input.backbutton:hover,
body .cform input.resetbutton:hover,
body .cform input.sendbutton:hover
{
	display:block !important;
	text-indent:-10000px !important;
	overflow:hidden !important;
	background:url(../images/get-started-go-button.png) top left no-repeat;
	height:70px !important;
	width:70px !important;
	margin-left:10px !important;
	border:none !important;
}
.gform_button.button:hover,
body .cform input.backbutton:hover,
body .cform input.resetbutton:hover,
body .cform input.sendbutton:hover
{
	background-position:0 -70px;
}



/*
OLD Cforms Stuff


.gfield span.reqtxt, .gfield span.emailreqtxt {
	color:$black;
	font-size:100%;
	margin:2px;
	padding:0;
	width:auto;
}

.gfield input.cf-box-a {
	margin-left:0;
}

.cform input#cf_field_5 {
	width:auto;
	float:left;
}

.cform label.cf-after span {
	width:auto;
	font-size:14px;
	padding:1px 0 0 20px;
}

.gfield ol.cf-ol{
	width:50%;
}

.cf-fs2 ol.cf-ol {
	float:right;
}

textarea#cf_field_8, textarea#cf_field_10 {
	height:36px;
}

textarea#cf_field_11 {
	height:72px;
}

.linklove {
	display:none;
}

.gfield fieldset.cf-fs1 {
	background:url(../images/get-started-pig-1.png) 425px 45px no-repeat;
	padding-bottom:30px;

}
.entry #cforms2form fieldset.cf-fs1 {
	background:url(../images/newsletter-pig-1.png) 425px 45px no-repeat;

}

fieldset.cf-fs2 {
	background:url(../images/get-started-pig-2.png) 20px 0 no-repeat;
	min-height:175px;
}

fieldset.cf-fs3 {
	background:url(../images/get-started-pig-3.png) 425px 25px no-repeat;
}

.gfield input.sendbutton ,
body .cform input.backbutton:hover,
body .cform input.resetbutton:hover,
body .cform input.sendbutton:hover
{
	display:block;
	text-indent:-10000px;
	overflow:hidden;
	background:url(../images/get-started-go-button.png) top left no-repeat;
	height:70px;
	width:70px;
	margin-left:10px;
	border:none;
}
body .cform input.backbutton:hover,
body .cform input.resetbutton:hover,
body .cform input.sendbutton:hover
{
	background-position:0 -70px;
}
body ol.cf-ol li.cf_li_err {
	background: none !important;
	border: none;
	margin: 5px 0 5px 10px !important;
	padding: 0 !important;
}
body ol.cf-ol li.cf_li_err label {
	color:$brand-primary;
}
body div.failure {
	background: $brand-primary !important;
	border: none;
	border-radius: 0;
	text-align:center;
	font-family: TorontoSubway;
}
body div.cf_info > span {
	text-shadow:none;
	color:$white;
	font-size:1.5em;
}
input[type="checkbox"] {
	border:none;
}

.cform label span {
	float:left;
}

.cform input:hover,
.cform textarea:hover,
.cform select:hover,
.cform input:focus,
.cform textarea:focus,
.cform select:focus
{
border:none !important;
box-shadow: none !important;

}
 */

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
/* Styles */

}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
/* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
/* Styles */
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
/* Styles */
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}

.gallery{
	.gallery-cell{
		&, img{
			width: 100%;
		}
	}
}
.flickity-prev-next-button{
	svg{
		.arrow{
			fill:$brand-primary;
		}
	}
	@media (max-width: $screen-xs-max){
		height: 8vw !important;
		width: 8vw !important;
		max-height: 44px;
		max-width: 44px;
		padding: 0 !important;
	}
}
.css-columns{
	.gen-con.row {
		.gen-con{
			-webkit-column-break-inside: avoid;
			break-inside               : avoid;
			page-break-inside          : avoid;
			width                      : auto;
			&.lookup-item{
				margin-top    : 0;
				margin-bottom : 0;
				padding-bottom: $ggw;
			}
		}
	}
	@media (min-width: $screen-sm) {
		@for $i from 2 through 4{
			&.cols-#{$i} {
				.column-content{
					@include content-columns($i);
				}
				.gen-con.row {
					@include content-columns($i, 0);
				}
			}
		}
	}
}
.flexbox-columns{
	@media (min-width: $screen-sm) {
		@for $i from 2 through 4{
			&.cols-#{$i} {
				.column-content{
					@include flexbox();
					@include flex-flow(row wrap)
				}
				.gen-con.row {
					@include flexbox();
					@include flex-flow(row wrap)
				}
			}
		}
	}
}
.gen-con.row{
	margin-bottom: $line-height-computed;
	$clear-sizes: 1, 2, 3, 4, 6, 12;
	@each $i in $clear-sizes {
		@media (max-width:$screen-xs-max){
			&.clear-xs-#{$i}{
				$child: floor(12 / $i);
				& > *:nth-child(#{$child}n +1 ){
					clear: left;
				}
			}
		}
		@media (min-width: $screen-sm) and (max-width:$screen-sm-max){
			&.clear-sm-#{$i}{
				$child: floor(12 / $i);
				& > *:nth-child(#{$child}n +1 ){
					clear: left;
				}
			}
		}
		@media (min-width: $screen-md) and (max-width:$screen-md-max){
			&.clear-md-#{$i}{
				$child: floor(12 / $i);
				& > *:nth-child(#{$child}n +1 ){
					clear: left;
				}
			}
		}
		@media (min-width: $screen-lg) and (max-width:$screen-lg-max){
			&.clear-lg-#{$i}{
				$child: floor(12 / $i);
				& > *:nth-child(#{$child}n +1 ){
					clear: left;
				}
			}
		}
		@media (min-width: $screen-xl){
			&.clear-xl-#{$i}{
				$child: floor(12 / $i);
				& > *:nth-child(#{$child}n +1 ){
					clear: left;
				}
			}
		}
	}
}
.no-break{
	white-space: nowrap;
}